window.Realworld = {
  invoke: (name: string, ...params: unknown[]) => {
    if (window.webkit?.messageHandlers) {
      window.webkit.messageHandlers[name].postMessage(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2556: A spread argument must either have a tuple type or be passed to a rest parameter.
        ...(params.length ? params : [null]),
      );
      return;
    }

    if (window.RealworldBridge) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TS2556: A spread argument must either have a tuple type or be passed to a rest parameter.
      window.RealworldBridge[name](...(params.length ? params : [null]));
    }
  },
  close: function () {
    window.Realworld.invoke('closeWebApp');
  },
};
